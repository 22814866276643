import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from 'ngx-myia-core';
import { CoreUIModule } from 'ngx-myia-core-ui';
import { LocalizationModule } from 'ngx-myia-localization';
import { CountDownComponent } from './components/countDown.component';
import { CountersCompletedPipe } from './components/countersCompletedPipe';
import { IsCounterActivePipe } from './components/isCounterActivePipe';
import { IsCounterWarningActivePipe } from './components/isCounterWarningActivePipe';
import { JitsiMeetComponent } from './components/jitsiMeet.component';

@NgModule({
  imports: [CommonModule, CoreModule, CoreUIModule, LocalizationModule],
  declarations: [
    CountersCompletedPipe,
    IsCounterActivePipe,
    JitsiMeetComponent,
    CountDownComponent,
    CountersCompletedPipe,
    IsCounterWarningActivePipe
  ],
  exports: [
    CountersCompletedPipe,
    IsCounterActivePipe,
    JitsiMeetComponent,
    CountDownComponent,
    CountersCompletedPipe,
    IsCounterWarningActivePipe
  ]
})
export class LiveMeetingModule {
}
