import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, RendererFactory2 } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { JitsiInstance } from '../jitsiInstance';

declare const JitsiMeetExternalAPI: any;
type buttonsConfigKeys = 'microphone' | 'camera' | 'closedcaptions' | 'desktop' | 'fullscreen' |
  'fodeviceselection' | 'hangup' | 'profile' | 'info' | 'chat' | 'recording' |
  'livestreaming' | 'etherpad' | 'sharedvideo' | 'settings' | 'raisehand' |
  'videoquality' | 'filmstrip' | 'invite' | 'feedback' | 'stats' | 'shortcuts' |
  'tileview' | 'videobackgroundblur' | 'download' | 'help' | 'mute-everyone' |
  'e2ee';

@Injectable({providedIn: 'root'})
export class JitsiAPIService {

  private _api: Observable<void>;

  constructor(private _rendererFactory: RendererFactory2, @Inject(DOCUMENT) private _document: Document) {
  }


  connectToRoom(containerElement: HTMLElement,
                options: {
                  roomName: string;
                  jwt?: string;
                  applyCustomConfigs?: boolean;
                  buttonsConfig?: { [key in buttonsConfigKeys]?: boolean };
                  customConfigs?: any;
                  onload?: () => void;
                  userInfo?: {
                    displayName: string;
                    email?: string;
                  }
                },
                domain = 'meet.jit.si'): Observable<JitsiInstance> {
    const toolBarButtons = [
      'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
      'fodeviceselection', 'hangup', 'profile', /* 'info', */ 'chat', /* 'recording',
        'livestreaming',*/ 'etherpad', /*'sharedvideo',*/ 'settings', 'raisehand',
      'videoquality', 'filmstrip' /*'invite'*/, 'feedback', 'stats', 'shortcuts',
      'tileview', /*'videobackgroundblur',*/ 'download', 'help', 'mute-everyone',
      /*'e2ee'*/
    ];
    if (options.buttonsConfig) {
      for (const [key, value] of Object.entries(options.buttonsConfig)) {
        if (value) {
          if (toolBarButtons.indexOf(key) === -1) {
            toolBarButtons.push(key);
          }
        } else {
          const keyIndex = toolBarButtons.indexOf(key);
          if (keyIndex !== -1) {
            toolBarButtons.splice(keyIndex, 1);
          }
        }
      }
    }
    return this.init(domain).pipe(
      map(() => {
        const apiOptions = {
          ...options,
          parentNode: containerElement,
          ...(options.applyCustomConfigs ? {
            // customize default config
            configOverwrite: {
              enableWelcomePage: false,
              enableCalendarIntegration: false,
              disableInviteFunctions: true,
              doNotStoreRoom: true,
              disableAudioLevels: true,
              prejoinPageEnabled: false,
              ...options.customConfigs
              // brandingDataUrl: 'https://myiaprod.blob.core.windows.net/streaming-resources/jitsi-branding/jitsi-myia-branding.json'
            },
            interfaceConfigOverwrite: {
              SHOW_JITSI_WATERMARK: false,
              SHOW_PROMOTIONAL_CLOSE_PAGE: false,
              SHOW_WATERMARK_FOR_GUESTS: false,
              SHOW_CHROME_EXTENSION_BANNER: false,
              TOOLBAR_BUTTONS: toolBarButtons,
              DISABLE_FOCUS_INDICATOR: true,
              RECENT_LIST_ENABLED: false,
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
              // DEFAULT_LOGO_URL: 'https://myiaprod.blob.core.windows.net/streaming-resources/jitsi-branding/watermark.png'

            }
          } : {})
        };
        return new JitsiInstance(options.roomName, new JitsiMeetExternalAPI(domain, apiOptions));
      })
    );
  }


  private init(domain: string): Observable<void> {
    if (!this._api) {
      const renderer = this._rendererFactory.createRenderer(null, null);
      this._api = new Observable((observer: Observer<void>) => {
        const script = renderer.createElement('script');
        script.src = `https://${domain}/external_api.js`;
        script.onload = () => {
          console.log('Jitsi API script loaded');
          observer.next();
          observer.complete();
        };
        renderer.appendChild(this._document.body, script);
      }).pipe(
        // cache -> return same result for all subscriptions
        publishReplay(1),
        refCount()
      );
    }
    return this._api;
  }
}
