import { Pipe, PipeTransform } from '@angular/core';
import { FormatDateService } from 'ngx-myia-localization';

@Pipe({
    name: 'isCounterWarningActive',
    pure: false  // update pipe even if timer instance reference is not changed
})
export class IsCounterWarningActivePipe implements PipeTransform {
    constructor(private _formatDateService: FormatDateService) {
    }

    transform(end: string | Date, warningDuration: number): boolean {
        if (end && warningDuration > 0) {
            const startDate = this._formatDateService.parseDate(end).add(-warningDuration, 'seconds');
            const endDate = this._formatDateService.parseDate(end);
            // console.log('isActive: ' + value.title + ' : ' + value.end  + ' - ' + this._formatDateService.isDateBetween(this._formatDateService.now(), startDate, endDate));
            return this._formatDateService.isDateBetween(this._formatDateService.now(), startDate, endDate);
        }
        return false;
    }

}
