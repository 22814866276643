import { EventEmitter } from '@angular/core';

export class JitsiInstance {
    // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe

    joined = new EventEmitter<void>();
    kickedOut = new EventEmitter<void>();
    hangUp = new EventEmitter<void>();

    private _participantId: string;

    constructor(public roomName: string, private _api: any) {
        _api.addEventListener('videoConferenceJoined', this.onVideoConferenceJoined.bind(this));
        _api.addEventListener('readyToClose', this.onHangUp.bind(this));
        _api.addEventListener('participantKickedOut', this.onKickedOut.bind(this));
        _api.addEventListener('participantRoleChanged', this.onParticipantRoleChanged.bind(this));
    }

    set subject(value: string) {
        this._api.executeCommand('subject', value);
    }

    set displayName(value: string) {
        this._api.executeCommand('displayName', value);
    }

    set avatarUrl(value: string) {
        this._api.executeCommand('avatarUrl', value);
    }

    close() {
        this._api.dispose();
    }

    toggleChat() {
        this._api.executeCommand('toggleChat');
    }

    toggleTileView() {
        this._api.executeCommand('toggleTileView');
    }

    private onVideoConferenceJoined(evtData: {
        roomName: string, // the room name of the conference
        id: string, // the id of the local participant
        displayName: string, // the display name of the local participant
        avatarURL: string // the avatar URL of the local participant
    }) {
        console.log('Jitsi room joined.');
        this._participantId = evtData.id;
        this.joined.emit();
    }

    private onHangUp() {
        this.hangUp.emit();
    }

    private onKickedOut(args: {
        kicked: {
            id: string, // the id of the participant removed from the room
            local: boolean // whether or not the participant is the local particiapnt
        },
        kicker: {
            id: string // the id of the participant who kicked out the other participant
        }
    })  {
        console.log('Participant kicked out');
        if (args.kicked.local) {
            this.kickedOut.emit();
        }
    }

    private onParticipantRoleChanged(args: {
        id: string, // the id of the participant
        displayName: string // the display name of the participant
    }) {
        console.log('Participant role changed');
    }

}
