import { animate, style, transition, trigger, useAnimation, animation } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Config } from 'ngx-myia-core';
import { FormatDateService } from 'ngx-myia-localization';
import { OnlineMeetingProvider } from '../entities/onlineMeetingProvider';
import { IScheduleLiveStateMeetingTimer } from '../entities/scheduleLiveStateMeetingTimer.interface';
import { JitsiInstance } from '../jitsiInstance';
import { CountersCompletedPipe } from './countersCompletedPipe';
import { JitsiAPIService } from '../services/jitsiAPIService';
import { fadeAnimation } from './fadeAnimation';

@Component({
  selector: 'jitsi-meet',
  animations: [
    trigger('fade', [
      transition(':enter', [
        useAnimation(fadeAnimation, {
          params: {
            opacityTo: 1,
            time: '1s'
          }
        })
      ]),
    ])
  ],
  template: `
    <div class="topBlock" [ngClass]="{waiting: !room && !(timers | CountersCompleted)}">
      <div class="title" *ngIf="!helpDeskRoomActive">
        <div [innerHTML]="(title || (defaultTitle | trans)) | sanitizeHtml"></div>
        <div class="subTitle" *ngIf="subTitle">{{subTitle}}</div>
      </div>
      <div class="title" *ngIf="helpDeskRoomActive">{{'Live|Help_Desk_Room' | trans}}</div>
      <div class="timers" *ngIf="!isLoading">
        <div class="timer" *ngFor="let timer of timers; last as isLast; trackBy: trackTimer" [ngClass]="{noLabel: !timer.title}">
          <div *ngIf="timer | isCounterActive">
            <div *ngIf="timer.title" class="timerTitle">{{timer.title}}</div>
            <count-down *ngIf="timer.end" [end]="timer.end" [warningDuration]="isLast? 60 : 0" [noFinalFade]="true" [showZero]="true" [showLabels]="false" (reached)="meetingTimerReached(timer)"></count-down>
          </div>
        </div>
      </div>
      <div class="meetingCompleted" *ngIf="timers | CountersCompleted" [@fade]>
        <svg-icon name="check"></svg-icon>
        <span>{{'Live|Live_Meeting_Completed'|trans}}</span>
      </div>
    </div>
    <div #jitsiContainer *ngIf="room && !(timers | CountersCompleted) && userDisconnectedRoomId !== room?.id" class="jitsiContainer" [ngClass]="{loading: isLoading}">
      <div class="jitsiLoadingBlock" *ngIf="isLoading">
        <progress-indicator-circle></progress-indicator-circle>
        <div class="msg">{{(helpDeskRoomActive ? 'Live|Help_Desk_Connecting' : 'Live|Live_Meeting_Connecting')|trans}}</div>
      </div>
      <div class="jitsiIframe"></div>
    </div>
    <!--        <iframe *ngIf="meetingUrl && !(timers | CountersCompleted)" allow="microphone; camera" [src]="meetingUrl" (load)="frameLoaded()"></iframe>-->
    <div class="noMeetingNow" *ngIf="!room && !(timers | CountersCompleted)">
      <div class="infoTxt" [innerHTML]="'Live|Live_Other_Meetings'|trans"></div>
    </div>
    <div class="disConnectedBlock" *ngIf="room && !(timers | CountersCompleted) && userDisconnectedRoomId === room?.id">
      <div class="infoTxt noBG" [innerHTML]="'Live|Live_Disconnected'|trans"></div>
      <button class="reconnectBtn" (click)="reconnect()">{{'Live|Live_Reconnect'|trans}}</button>
    </div>
    <div class="bottomBlock" *ngIf="showHelpDeskSupportLink">
      <div *ngIf="!helpDeskRoomActive">
        <div class="desc">{{'Live|Help_Desk_Connect_Title'|trans}}</div>
        <button (click)="showHelpDeskRoom()">{{'Live|Help_Desk_Connect'|trans}}</button>
      </div>
      <div *ngIf="helpDeskRoomActive">
        <button (click)="leaveHelpDeskRoom()">{{'Live|Leave_Help_Desk'|trans}}</button>
      </div>
    </div>
  `,
  styleUrls: ['./jitsiMeet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JitsiMeetComponent implements OnInit, OnChanges, OnDestroy {

  // https://jitsi.github.io/handbook/docs/dev-guide/dev-guide-iframe

  @Input() provider: OnlineMeetingProvider = OnlineMeetingProvider.jitsi;

  @Input() helpDeskRoomId = 'helpDesk';

  @Input() room: { id: string; token: string; domain?: string};

  @Input() startWithVideoMuted: boolean;

  @Input() title: string;

  @Input() subTitle: string;

  @Input() nick: string;

  @Input() defaultTitle: string;

  @Input() avatarUrl: string;

  @Input() showHelpDeskSupportLink: boolean;

  @Input() useTileView: boolean;

  @Input() timers: ReadonlyArray<IScheduleLiveStateMeetingTimer>;

  @Output()
  timerElapsed = new EventEmitter<IScheduleLiveStateMeetingTimer>();

  @Output()
  loaded = new EventEmitter<void>();

  @Output()
  hangUp = new EventEmitter<void>();

  @ViewChild('jitsiContainer', {static: false})
  set jitsiContainer(value: ElementRef) {
    const oldEl = this._jitsiContainer ? this._jitsiContainer.nativeElement : null;
    const newEl = value ? value.nativeElement : null;
    this._jitsiContainer = value;
    if (oldEl !== newEl) {
      this.connect();
    }
  };


  isLoading: boolean;

  userDisconnectedRoomId: string | null;

  helpDeskRoomActive = false;

  @HostBinding('class.completed')
  get meetingCompleted() {
    return CountersCompletedPipe.isCompleted(this.timers, this._formatDateService);
  }

  // get meetingUrl(): SafeResourceUrl {
  //     if (!this.roomId) {
  //         return null;
  //     }
  //     const url = `https://${this.meetDomain || this.defaultMeetDomain}/${this.roomId}#config.startWithVideoMuted=${!!this.startWithVideoMuted}`;
  //     return this._sanitizer.bypassSecurityTrustResourceUrl(url);
  // }

  private get defaultMeetDomain(): string {
    return this.provider === OnlineMeetingProvider.jitsi ? Config.get<string>('jitsiDomain', 'meet.jit.si') : '8x8.vc';
  }

  private _jitsiInstance: JitsiInstance | null;
  private _jitsiContainer: ElementRef;

  constructor(private _sanitizer: DomSanitizer, private _formatDateService: FormatDateService, private _cdr: ChangeDetectorRef, private _jitsiAPIService: JitsiAPIService) {
  }

  ngOnInit() {
    this.connect();
  }

  ngOnDestroy() {
    this.disconnect();
  }

  ngOnChanges(changes: SimpleChanges): void {
    let shouldConnectToRoom = false;
    // tslint:disable-next-line:forin
    for (const propName in changes) {
      if (propName === 'meetDomain' || propName === 'roomId' || propName === 'jwt') {
        shouldConnectToRoom = true;
      }

      if (propName === 'nick' && this._jitsiInstance) {
        this._jitsiInstance.displayName = changes[propName].currentValue;
      }

      if (propName === 'avatarUrl' && this._jitsiInstance) {
        this._jitsiInstance.avatarUrl = changes[propName].currentValue;
      }
    }
    if (shouldConnectToRoom) {
      this.connect();
    }
  }

  frameLoaded() {
    // this.isLoading = false;
    // this._cdr.markForCheck();
    console.log('Jitsi iframe loaded.');
    if (this.helpDeskRoomActive) {
      // room in lobby mode dont send 'participantJoined' event, before admin dont confirms join request
      this.onJitsiRoomLoaded();
    }
  }

  meetingTimerReached(timer: IScheduleLiveStateMeetingTimer) {
    this.timerElapsed.emit(timer);
    setTimeout(() => {
      // modify timers to allow change detection for connected pure pipes
      this.timers = [
        ...this.timers
      ];
      this._cdr.markForCheck();
    });
  }

  trackTimer(index: number, timer: IScheduleLiveStateMeetingTimer) {
    return `${timer.title || ''}_${timer.duration}`;
  }

  reconnect() {
    this.userDisconnectedRoomId = null;
    this.connect();
  }

  showHelpDeskRoom() {
    this.disconnect();
    this.helpDeskRoomActive = true;
    this.reconnect();
  }

  leaveHelpDeskRoom() {
    this.disconnect();
    this.helpDeskRoomActive = false;
    this.reconnect();
  }


  private connect() {
    if (this.room && this.userDisconnectedRoomId === this.room?.id) {
      return;
    }
    this.userDisconnectedRoomId = null;
    this._cdr.markForCheck();
    this.disconnect();
    if (this.room && this._jitsiContainer) {
      this.isLoading = true;
      this._cdr.markForCheck();
      this._cdr.detectChanges();
      if (this.provider === OnlineMeetingProvider.Jaas && !this.room) {
        console.log(`Waiting for jwt token ...`);
        return;
      }
      console.log(`Opening room: ${this.room?.id}`);
      setTimeout(() => {
        const jitsiIframe = this._jitsiContainer.nativeElement.querySelector('.jitsiIframe');
        jitsiIframe.innerHTML = '';
        this._jitsiAPIService.connectToRoom(jitsiIframe,
          {
            roomName: this.helpDeskRoomActive ? this.helpDeskRoomId : this.room?.id,
            jwt: this.room.token,
            onload: this.frameLoaded.bind(this),
            applyCustomConfigs: !this.helpDeskRoomActive, // !this.meetDomain,
            customConfigs: {
              disableInviteFunctions: true
            },
            buttonsConfig: {
              chat: false,
              closedcaptions: false,
              download: false,
              'mute-everyone': false,
              feedback: false,
              ...(this.provider === OnlineMeetingProvider.Jaas ? {
                recording: true,
                livestreaming: true
              } : {})
            },
            userInfo: {
              displayName: this.nick
            }
          },
          this.room.domain || this.defaultMeetDomain).subscribe(jitsiInstance => {

          this._jitsiInstance = jitsiInstance;

          jitsiInstance.joined.subscribe(() => {
            this.onJitsiRoomLoaded();
          });
          jitsiInstance.kickedOut.subscribe(() => {
            if (this.helpDeskRoomActive) {
              this.leaveHelpDeskRoom();
            }
          });

          jitsiInstance.hangUp.subscribe(() => {
            console.log('Hang up');
            if (this.helpDeskRoomActive) {
              this.leaveHelpDeskRoom();
            } else {
              this.userDisconnectedRoomId = jitsiInstance.roomName;
              this._cdr.markForCheck();
            }
            this.hangUp.emit();
          });

          jitsiInstance.subject = ' '; // hide meeting room name in jitsi component

        });
      });
    }
  }

  private disconnect() {
    if (this._jitsiInstance) {
      this._jitsiInstance.close();
      this._jitsiInstance = null;
    }
  }

  private onJitsiRoomLoaded() {
    console.log('Joined to room');
    if (this._jitsiInstance) {
      this._jitsiInstance.avatarUrl = this.avatarUrl;
      this.loaded.emit();
      this.isLoading = false;
      this._cdr.markForCheck();
      if (this.useTileView && !this.helpDeskRoomActive) {
        this._jitsiInstance.toggleTileView();
      }
      if (this.helpDeskRoomActive) {
        this._jitsiInstance.toggleChat();
      }
    }
  }
}

